import React from 'react';
import produce from 'immer';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export default class ImageStrip extends React.Component {
  constructor(props) {
    super(props);

    this.forceShow = this.forceShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleMoveNext = this.handleMoveNext.bind(this);
    this.handleMovePrev = this.handleMovePrev.bind(this);

    this.state = {
      showIndex: null, // the index (number) into 'keys' to show
      images: {}, // the image data, indexed by '1-across'-style keys
      keys: [], // the keys in canonical order
    };
  }

  // Adds an image to the strip, and (might) show it...
  addImage(key, { data, title, caption }, show = true) {
    // If we already have the image, there's nothing to do
    if (this.state.images[key]) {
      return;
    }

    // Otherwise, add and show...
    this.setState(
      produce(draft => {
        draft.images[key] = { data, title, caption };
        draft.keys.push(key);
        draft.keys.sort();
        if (show) {
          draft.showIndex = draft.keys.indexOf(key);
        }
      }),
    );
  }

  forceShow(key) {
    console.log('force show', key);
    this.setState(
      produce(draft => {
        draft.showIndex = draft.keys.indexOf(key);
      }),
    );
  }

  reset() {
    this.setState({
      showIndex: null, // the index (number) into 'keys' to show
      images: {}, // the image data, indexed by '1-across'-style keys
      keys: [], // the keys in canonical order
    });
  }

  handleClose() {
    // console.log('HANDLE CLOSE');
    this.setState({ showIndex: null });
  }

  handleMovePrev() {
    // console.log('HANDLE MOVE PREV');
    this.setState(
      produce(draft => {
        draft.showIndex =
          (draft.showIndex + draft.keys.length - 1) % draft.keys.length;
      }),
    );
  }

  handleMoveNext() {
    // console.log('HANDLE MOVE NEXT');
    this.setState(
      produce(draft => {
        draft.showIndex = (draft.showIndex + 1) % draft.keys.length;
      }),
    );
  }

  render() {
    const { showIndex, keys, images } = this.state;
    const image = images[keys[showIndex]];
    const prevImage = images[keys[(showIndex + keys.length - 1) % keys.length]];
    const nextImage = images[keys[(showIndex + 1) % keys.length]];

    return (
      <div>
        {keys.map(key => (
          <img
            key={key}
            src={images[key].data.images[1].path}
            alt=""
            onClick={() => this.forceShow(key)}
            style={{ margin: '1em', border: '10px solid black' }}
          />
        ))}

        {showIndex !== null && (
          <Lightbox
            onCloseRequest={this.handleClose}
            mainSrc={image.data.src}
            imageTitle={`${showIndex + 1} of ${keys.length}: ${image.title}`}
            imageCaption={image.caption}
            prevSrc={prevImage.data.src}
            nextSrc={nextImage.data.src}
            onMovePrevRequest={this.handleMovePrev}
            onMoveNextRequest={this.handleMoveNext}
          />
        )}
      </div>
    );
  }
}
