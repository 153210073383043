import React from 'react';
import styled from 'styled-components';
import produce from 'immer';

import Crossword from './crossword';
import ImageStrip from './imagestrip';

import data from './crosswordData';

// import './App.css';
import { background } from './images';

const Page = styled.div`
  padding: 1rem;
  /* font-family: Candara, Calibri, Segoe, 'Segoe UI', Optima, Arial, sans-serif; */
  font-family: Corbel, 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans',
    'DejaVu Sans', 'Bitstream Vera Sans', 'Liberation Sans', Verdana,
    'Verdana Ref', sans-serif;
  font-size: 1.25rem;
  /* height: 100%; */
  /* width: 100%; */
  background-image: url(${background.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  /* make the clues "boxed" */
  .grid {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1rem;
    margin: 1rem;
    border-radius: 0.5rem;
  }

  .clues {
    background-color: rgba(255, 255, 255, 0.75);
    padding: 1rem;
    margin: 1rem;
    border-radius: 0.5rem;
  }
`;

const Boxed = styled.div`
  background-color: rgba(255, 255, 255, 0.75);
  padding: 1rem;
  margin: 1rem;
  border-radius: 0.5rem;
`;

const Intro = styled(Boxed)``;

const Title = styled.header`
  font-size: 150%;
`;

const Button = styled.button`
  font-family: inherit;
  font-size: inherit;
  margin-left: 1rem;
`;

class App extends React.Component {
  constructor(props) {
    super(props);

    this.crosswordRef = React.createRef();
    this.imagestrip = null; // React.createRef();

    this.setImagestrip = this.setImagestrip.bind(this);
    this.handleCorrectAnswer = this.handleCorrectAnswer.bind(this);
    this.handleLoadedCorrectAnswers = this.handleLoadedCorrectAnswers.bind(
      this,
    );

    this.fillAllAnswers = this.fillAllAnswers.bind(this);
    this.resetCrossword = this.resetCrossword.bind(this);

    this.state = {
      waitingCorrect: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.waitingCorrect !== prevState.waitingCorrect) {
      this.addWaitingImages();
    }
  }

  setImagestrip(element) {
    // console.log('SET IMAGESTRIP', element);
    this.imagestrip = element;

    // If we have queued loaded-correct answers, handle them now.
    this.addWaitingImages();
  }

  addWaitingImages() {
    const { waitingCorrect } = this.state;
    if (this.imagestrip && waitingCorrect.length > 0) {
      // console.log('adding queued correct images');
      waitingCorrect.forEach(val => {
        // console.log('image...', val);
        this.imagestrip.addImage(...val);
      });
      this.setState({ waitingCorrect: [] });
    }
  }

  handleCorrectAnswer(direction, number, answer, show = true) {
    // look for image for that clue...
    const { image } = data[direction][number];
    // console.log('image for', direction, number, image);
    if (image) {
      const key = `${number}-${direction}`;
      if (this.imagestrip) {
        this.imagestrip.addImage(key, image, show);
      } else {
        // console.log('queuing correct image...');
        this.setState(
          produce(draft => {
            draft.waitingCorrect.push([key, image, show]);
          }),
        );
      }
    }
  }

  handleLoadedCorrectAnswers(loadedCorrect) {
    loadedCorrect.forEach(([direction, number, answer]) => {
      this.handleCorrectAnswer(direction, number, answer, false);
    });
  }

  fillAllAnswers(event) {
    event.preventDefault();
    if (this.crosswordRef.current) {
      this.crosswordRef.current.fillAllAnswers();
    }

    // and force-show the first one...
    if (this.imagestrip) {
      this.imagestrip.forceShow('1-down');
    }
  }

  resetCrossword(event) {
    event.preventDefault();

    this.setState({ waitingCorrect: [] });

    if (this.crosswordRef.current) {
      this.crosswordRef.current.reset();
    }

    if (this.imagestrip) {
      this.imagestrip.reset();
    }
  }

  render() {
    return (
      <Page>
        <Intro>
          <Title>
            Merry Christmas and Happy New Year’s from the Southwest Reisingers!
          </Title>

          <p>
            It’s been too long since we sent a Christmas greetings and merry
            wishes card, so we have dipped a bit into the past year and a half
            to give you some fun insight into our latest happenings. Though
            cards have been absent, the sentiments and love that we have for you
            have not dwindled in the least. Wishing you the warmest the season
            has to offer and a blessed new year!
          </p>

          <p>
            Solve the crossword puzzle to see the pictures!
            <Button onClick={this.fillAllAnswers}>
              I give up! Show me the pictures!
            </Button>
            <Button onClick={this.resetCrossword}>
              Gosh that was fun! Let me do it all over again!
            </Button>
          </p>
        </Intro>
        <Crossword
          ref={this.crosswordRef}
          data={data}
          gridBackground="transparent"
          cellBorder="rgba(0,0,0, 0.5)"
          cellBackground="rgb(255,255,255)"
          textColor="rgb(0,102,0)"
          highlightBackground="rgb(255,204,204)"
          focusBackground="rgb(255,102,102)"
          // columnBreakpoint="1000px"
          onCorrect={this.handleCorrectAnswer}
          onLoadedCorrect={this.handleLoadedCorrectAnswers}
        />

        <ImageStrip ref={this.setImagestrip} />
      </Page>
    );
  }
}

export default App;
