import React from 'react';
import PropTypes from 'prop-types';

// expected props: row, col, answer, crossword, cellSize

class Cell extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    event.preventDefault();
    // console.log('CELL CLICK', this.props.row, this.props.col);
    const { onClick, cellData } = this.props;
    if (onClick) {
      onClick(cellData);
    }
  }

  render() {
    const { cellData, renderContext, focus, highlight } = this.props;
    const { row, col, guess, number } = cellData;

    if (!renderContext) {
      return null;
    }

    const {
      // gridBackground,
      cellBackground,
      cellBorder,
      textColor,
      numberColor,
      focusBackground,
      highlightBackground,
      cellSize,
      cellPadding,
      cellInner,
      cellHalf,
      fontSize,
    } = renderContext;

    const x = col * cellSize;
    const y = row * cellSize;

    return (
      <g
        onClick={this.handleClick}
        style={{ cursor: 'default', fontSize: `${fontSize}px` }}
      >
        <rect
          x={x + cellPadding}
          y={y + cellPadding}
          width={cellInner}
          height={cellInner}
          fill={
            focus
              ? focusBackground
              : highlight
              ? highlightBackground
              : cellBackground
          }
          stroke={cellBorder}
          strokeWidth={cellSize / 50}
        />
        {number && (
          <text
            x={x + cellPadding * 4}
            y={y + cellPadding * 4}
            textAnchor="start"
            dominantBaseline="hanging"
            style={{ fontSize: '50%', fill: numberColor }}
          >
            {number}
          </text>
        )}
        <text
          x={x + cellHalf}
          y={y + cellHalf + 1} // +1 for visual alignment?
          textAnchor="middle"
          dominantBaseline="middle"
          style={{ fill: textColor }}
        >
          {guess}
        </text>
      </g>
    );
  }
}

Cell.propTypes = {
  cellData: PropTypes.object.isRequired,
  renderContext: PropTypes.object.isRequired,
  focus: PropTypes.bool.isRequired,
  highlight: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

export default Cell;
